import { defineMessages } from 'react-intl';

import { STATISTICS_TILES_TYPES, STATISTICS_TYPES } from './constants';

export default defineMessages({
  ACHIEVEMENTS: {
    defaultMessage: 'Badges',
  },

  TILE_SUBTITLE: {
    defaultMessage: '{value}',
  },
});

export const statisticsTileTypesMessages =
  defineMessages<STATISTICS_TILES_TYPES>({
    [STATISTICS_TILES_TYPES.GENERAL]: {
      defaultMessage: 'Général',
    },

    [STATISTICS_TILES_TYPES.PROGRESSION]: {
      defaultMessage: 'Progression',
    },

    [STATISTICS_TILES_TYPES.VIRTUAL_CLASSROOMS]: {
      defaultMessage: 'Classes virtuelles',
    },

    [STATISTICS_TILES_TYPES.ASSIGNMENTS]: {
      defaultMessage: 'Rendus',
    },
  } as const);

export const statisticsTileTitleMessages = defineMessages<STATISTICS_TYPES>({
  [STATISTICS_TYPES.ASSIGNMENTS]: {
    defaultMessage: 'Devoirs terminés',
  },

  [STATISTICS_TYPES.COMPLETED_SEQUENCES]: {
    defaultMessage: 'Séquences validées',
  },

  [STATISTICS_TYPES.DOWNLOADED_SEQUENCES]: {
    defaultMessage: 'Séquences téléchargées',
  },

  [STATISTICS_TYPES.EXERCISES]: {
    defaultMessage: 'Entraînements terminés',
  },

  [STATISTICS_TYPES.MAX_CONNECTION_STREAK]: {
    defaultMessage: 'Record de connexions',
  },

  [STATISTICS_TYPES.NB_AUDIO_LISTENED]: {
    defaultMessage: "Nombre d'écoutes",
  },

  [STATISTICS_TYPES.NB_MEETINGS]: {
    defaultMessage: 'RDV pris',
  },

  [STATISTICS_TYPES.NB_REPLAYS_SEEN]: {
    defaultMessage: 'Replays visionnés',
  },

  [STATISTICS_TYPES.NB_VIRTUAL_CLASSROOMS_SEEN]: {
    defaultMessage: 'Lives visionnés',
  },

  [STATISTICS_TYPES.NB_RESOURCES_SEEN]: {
    defaultMessage: 'Ressources visionnées',
  },

  [STATISTICS_TYPES.TOTAL_SESSIONS_DURATION]: {
    defaultMessage: 'Temps total',
  },
} as const);
