import {
  browserTracingIntegration,
  type EventHint,
  init as initSentry,
  replayIntegration,
} from '@sentry/react';

import { GLOBAL_ERRORS } from '@eversity/domain/constants';
import { type BootstrapSentryOptions } from '@eversity/types/misc';
import { isResponseError } from '@eversity/types/web';
import { type ApiErrorResponse } from '@eversity/utils/express';

export const bootstrapSentryReact = ({
  ignoreAgents = [
    'GoogleHC/1.0',
    'Elastic-Heartbeat/7.17.5',
    'python-requests/2.28.1',
  ],
  enabled,
  dsn,
  release,
  env,
}: BootstrapSentryOptions) => {
  if (enabled) {
    initSentry({
      dsn,
      environment: env,
      release,
      replaysSessionSampleRate: env === 'prod' ? 0.1 : 0.0,
      replaysOnErrorSampleRate: env === 'prod' ? 1.0 : 0.0,
      integrations: [
        browserTracingIntegration(),
        replayIntegration({
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
      tracesSampleRate: env === 'prod' ? 1.0 : 0.0,
      tracesSampler: (samplingContext) => {
        if (
          ignoreAgents.includes(
            (samplingContext?.request?.headers || {})['user-agent'],
          )
        )
          return false;
        return 0.5;
      },
      beforeSend(event, hint: EventHint) {
        const error = hint.originalException as Error;

        if (isResponseError(error)) {
          if (
            error.status === 401 &&
            error?.response?.body &&
            (error.response.body as ApiErrorResponse).message ===
              GLOBAL_ERRORS.USER_NOT_AUTHENTICATED.MESSAGE
          ) {
            return null;
          }
        }

        return event;
      },
    });
  }
};
