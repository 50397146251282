import 'react-loading-skeleton/dist/skeleton.css';

import {
  cloneElement,
  memo,
  type MemoExoticComponent,
  type ReactElement,
} from 'react';
import Skeleton from 'react-loading-skeleton';
import { useTheme } from '@emotion/react';
import cn from 'classnames';

import { Truncate } from '@eversity/ui/utils';

import { COLOR_GROUPS } from '../../../config/colors/constants';
import { Typography } from '../../general/typography/Typography';
import { TILE_VARIANTS } from './constants';
import * as styles from './Tile.styles';

export type TileProps = {
  icon?: ReactElement;
  title?: string;
  subtitle?: string;
  variant?: TILE_VARIANTS;
  isLoading?: boolean;
  isDisabled?: boolean;
};

/**
 * A tile component that displays an icon, title, and subtitle with customizable styles and states.
 * It supports a loading state, disabled state, and multiple visual variants for flexible usage.
 *
 * ### Features
 * - Displays an icon with customizable colors based on the `variant` and `isDisabled` props.
 * - Shows a title and subtitle, or skeleton loaders if `isLoading` is true.
 * - Supports visual variants defined in `VARIANTS` for consistent styling.
 * - Adapts its appearance when `isDisabled` is true.
 *
 * @example
 * // Example usage of the Tile component
 * <Tile
 *   icon={<SomeIcon />}
 *   title="Dashboard"
 *   subtitle="Manage your settings"
 *   variant={Tile.VARIANTS.SECONDARY}
 *   isLoading={false}
 *   isDisabled={false}
 * />
 *
 * @param {Object} props - The props for the Tile component.
 * @param {ReactElement} [props.icon] - An optional icon element to display at the top of the tile.
 * @param {string} [props.title] - The main title text for the tile.
 * @param {string} [props.subtitle] - The subtitle text for the tile.
 * @param {VARIANTS} [props.variant=VARIANTS.PRIMARY] - The style variant of the tile (`PRIMARY`, `SECONDARY`, etc.).
 * @param {boolean} [props.isLoading=false] - Whether the tile is in a loading state, displaying skeletons instead of content.
 * @param {boolean} [props.isDisabled=false] - Whether the tile is disabled, affecting its appearance and interaction.
 *
 * @see {@link VARIANTS} for available style variants.
 * @see {@link Typography} for the typography options used in the component.
 */

export const TileBase = ({
  icon,
  title,
  subtitle,
  variant = TILE_VARIANTS.PRIMARY,
  isLoading = false,
  isDisabled = false,
}: TileProps) => {
  const { colors } = useTheme();

  const colorsGroup = isDisabled ? COLOR_GROUPS.GRAY : variant.toLowerCase();

  const iconFillColors = isDisabled
    ? [colors.gray[400], colors.gray[50]]
    : [colors[colorsGroup][500], colors[colorsGroup][50]];

  return (
    <div
      css={styles.container}
      className={cn({ [variant]: !isDisabled, isDisabled })}
    >
      {isLoading ? (
        <Skeleton
          height={54}
          width={54}
          baseColor={colors[colorsGroup][50]}
        />
      ) : (
        <div
          css={styles.iconContainer}
          className={cn({ [variant]: !isDisabled, isDisabled })}
        >
          {cloneElement(icon, {
            size: 24,
            fill: iconFillColors,
          })}
        </div>
      )}

      <div>
        {isLoading ? (
          <Skeleton
            height={30}
            width={120}
            baseColor={colors[colorsGroup][50]}
          />
        ) : (
          <Truncate maxLines={1}>
            <Typography variant={Typography.VARIANTS.BODY_LARGE_BOLD}>
              {title}
            </Typography>
          </Truncate>
        )}

        {isLoading ? (
          <Skeleton
            height={20}
            width={70}
            baseColor={colors[colorsGroup][50]}
          />
        ) : (
          <Truncate maxLines={1}>
            <Typography variant={Typography.VARIANTS.BODY_LARGE_REGULAR}>
              {subtitle}
            </Typography>
          </Truncate>
        )}
      </div>
    </div>
  );
};

export const Tile: MemoExoticComponent<typeof TileBase> & {
  VARIANTS?: typeof TILE_VARIANTS;
} = memo(TileBase);

Tile.VARIANTS = TILE_VARIANTS;
