import PropTypes from 'prop-types';

import { COURSE_DIPLOMA_TYPES, USER_ROLES } from '@eversity/domain/constants';
import { uploadPropTypes } from '@eversity/ui/design-system';

import { schoolObjectBasePropTypes } from './common';

export const commonUserPropTypes = PropTypes.shape({
  /** Unique id. */
  id: PropTypes.string.isRequired,
  /** First name. */
  firstName: PropTypes.string.isRequired,
  /** Last name. */
  lastName: PropTypes.string.isRequired,
  /** Email address. */
  email: PropTypes.string.isRequired,
  /** Picture if any. */
  picture: uploadPropTypes,
});

export const employeeViewFullPropTypes = PropTypes.shape({
  /** Unique id. */
  id: PropTypes.string.isRequired,
  /** First name. */
  firstName: PropTypes.string.isRequired,
  /** Last name. */
  lastName: PropTypes.string.isRequired,
  /** Email address. */
  email: PropTypes.string.isRequired,
  /** User roles. */
  roles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(USER_ROLES)))
    .isRequired,
  /** Picture if any. */
  picture: uploadPropTypes,
  /** History of profile pictures for this employee. */
  picturesHistory: PropTypes.arrayOf(uploadPropTypes).isRequired,
  /** Calendly appointments url. */
  calendlyUrl: PropTypes.string,
  /** Creation date. */
  createdAt: PropTypes.string.isRequired,
  /** Last update date. */
  updatedAt: PropTypes.string.isRequired,
});

export const studentCoursePropTypes = PropTypes.shape({
  /** Course data. */
  course: PropTypes.shape({
    /** Course id. */
    id: PropTypes.string.isRequired,
    /** Course title. */
    title: PropTypes.string.isRequired,
    /** Course code. */
    code: PropTypes.string.isRequired,
    /** Course diploma type. */
    diplomaType: PropTypes.oneOf(Object.values(COURSE_DIPLOMA_TYPES))
      .isRequired,
  }),
  /** Class data. */
  class: schoolObjectBasePropTypes.isRequired,
  /** List of optional lesson ids picked for the user. */
  optionalLessons: PropTypes.arrayOf(
    PropTypes.shape({
      /** Technical id. */
      id: PropTypes.string.isRequired,
      /** Lesson id. */
      lesson: PropTypes.string.isRequired,
      /** Is optional lesson activated. */
      enabled: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  /** Date of registration for this course for the user. */
  registrationDate: PropTypes.string.isRequired,
});

export const studentViewFullPropTypes = PropTypes.shape({
  /** Unique id. */
  id: PropTypes.string.isRequired,
  /** First name. */
  firstName: PropTypes.string.isRequired,
  /** Last name. */
  lastName: PropTypes.string.isRequired,
  /** Email address. */
  email: PropTypes.string.isRequired,
  /** Picture if any. */
  picture: uploadPropTypes,
  /** History of profile pictures for this student. */
  picturesHistory: PropTypes.arrayOf(uploadPropTypes).isRequired,
  /** CRM student id. */
  studentRef: PropTypes.string.isRequired,
  /** Student date of birth. */
  dateOfBirth: PropTypes.string,
  /** Courses of the student. */
  courses: PropTypes.arrayOf(studentCoursePropTypes).isRequired,
  /** Creation date. */
  createdAt: PropTypes.string.isRequired,
  /** Last update date. */
  updatedAt: PropTypes.string.isRequired,
});
