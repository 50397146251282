import { defineMessages } from 'react-intl';

import { ACHIEVEMENT_TYPES } from '@eversity/domain/constants';

export const achievementsTitles = defineMessages<ACHIEVEMENT_TYPES>({
  [ACHIEVEMENT_TYPES.CONNECTION_STREAK]: {
    defaultMessage: '{count} séries',
  },
});

export const achievementsDescriptions = defineMessages<ACHIEVEMENT_TYPES>({
  [ACHIEVEMENT_TYPES.CONNECTION_STREAK]: {
    defaultMessage: "Revenez sur Didaskill {count} jours d'affilée",
  },
});
