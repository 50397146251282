import { memo, type ReactNode, useMemo } from 'react';
import { type IntlConfig, IntlProvider as ReactIntlProvider } from 'react-intl';

import { useLocaleContext } from '../locale-context/LocaleContext';

export type IntlProviderProps = {
  defaultLocale?: string;
  defaultRichTextElements?: IntlConfig['defaultRichTextElements'];
  children?: ReactNode;
};

export const IntlProviderBase = ({
  defaultLocale = 'fr',
  defaultRichTextElements: propsDefaultRichTextElements = undefined,
  children = null,
  ...props
}: IntlProviderProps) => {
  const { locale, messages } = useLocaleContext();

  const defaultRichTextElements = useMemo(
    () => ({
      br: <br />,
      p: (chunk: ReactNode) => <p>{chunk}</p>,
      ol: (chunk: ReactNode) => <ol>{chunk}</ol>,
      ul: (chunk: ReactNode) => <ul>{chunk}</ul>,
      li: (chunk: ReactNode) => <li>{chunk}</li>,
      ...propsDefaultRichTextElements,
    }),
    [propsDefaultRichTextElements],
  );

  return (
    <ReactIntlProvider
      {...props}
      defaultLocale={defaultLocale}
      locale={locale}
      messages={messages}
      // @ts-ignore Passing br: <br/> works but not br: () => <br/>.
      defaultRichTextElements={defaultRichTextElements}
    >
      {children}
    </ReactIntlProvider>
  );
};

IntlProviderBase.displayName = 'IntlProvider';

export const IntlProvider = memo(IntlProviderBase);
