import { type ComponentProps, memo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import { type FallbackRender } from '@sentry/react';
import { noop } from 'lodash';
import { type ResponseError } from 'superagent';

import { isErrorWithStatus } from '@eversity/utils/misc';

import { NotFoundError } from '../../pages/errors/not-found/NotFoundError';
import { InternalServerError } from '../../pages/errors/server-error/InternalServerError';
import { commonRoutes } from '../../routes/routes';

export type ErrorViewProps = Partial<
  Omit<ComponentProps<FallbackRender>, 'error'>
> & {
  error?: ComponentProps<FallbackRender>['error'] | ResponseError;
};

export const ErrorViewBase = ({
  error = null,
  resetError = noop,
}: ErrorViewProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onReturnToHome = useCallback(
    () => navigate(commonRoutes.APP.ROOT, { replace: true }),
    [navigate],
  );

  useUpdateEffect(() => {
    resetError();
  }, [location.pathname, resetError]);

  const renderError = () => {
    if (!isErrorWithStatus(error)) {
      return <NotFoundError onReturnToHome={onReturnToHome} />;
    }

    switch (error.status) {
      case 500:
        return <InternalServerError />;
      case 401:
        window.location.href = commonRoutes.LOGIN.ROOT;
        return null;
      default:
        return <NotFoundError onReturnToHome={onReturnToHome} />;
    }
  };

  return renderError();
};

ErrorViewBase.displayName = 'ErrorView';

export const ErrorView = memo(ErrorViewBase);
