import {
  ACHIEVEMENT_TIERS,
  ACHIEVEMENT_TIERS_THRESHOLDS,
  ACHIEVEMENT_TYPES,
  USER_ACCOUNT_TYPES,
  USER_ROLES,
} from '@eversity/domain/constants';
import { type StudentAchievement } from '@eversity/types/domain';

/**
 * Check if the user is a test account based on the role.
 *
 * @param user - User to check.
 * @returns - True if the user is a test account.
 */
export const isUserTestAccount = ({
  roles,
}: {
  [key: string]: unknown;
  roles: USER_ROLES[];
}): boolean => {
  return !!roles?.includes(USER_ROLES.CAMPUS_TESTER);
};

/**
 * Check if the user is a student based on the account type.
 *
 * @param accountType - Account type.
 * @returns - True if the user is a student.
 */
export const isUserStudent = (accountType: USER_ACCOUNT_TYPES) =>
  accountType === USER_ACCOUNT_TYPES.STUDENT;

/**
 * Get the achievement tiers based on the value of the threshold.
 *
 * @param type - Achievement type.
 * @param value - Threshold value.
 * @returns - Corresponding achievement tiers.
 */
export const getAchievementTiersFromThreshold = (
  type: ACHIEVEMENT_TYPES,
  value: number,
) => {
  const thresholds = ACHIEVEMENT_TIERS_THRESHOLDS[type];

  if (!thresholds) {
    return [];
  }

  const tiers = Object.entries(thresholds)
    .filter((keyValue) => value >= keyValue[1])
    .map((keyValue) => keyValue[0]);

  return tiers as ACHIEVEMENT_TIERS[];
};

/**
 * Get all existing achievements.
 *
 * @returns - All existing achievements.
 */
export const getExistingAchievements = () => {
  return Object.values(ACHIEVEMENT_TYPES).flatMap((type) => {
    const thresholds = ACHIEVEMENT_TIERS_THRESHOLDS[type];

    return Object.values(ACHIEVEMENT_TIERS).map((tier) => ({
      type,
      tier,
      threshold: thresholds[tier],
    }));
  });
};

/**
 * Get sorted achievements.
 *
 * @param achievements - Achievements to sort.
 * @returns - Sorted achievements.
 */
export const getSortedAchievements = (achievements: StudentAchievement[]) => {
  return achievements.sort((a, b) => {
    const aThreshold = ACHIEVEMENT_TIERS_THRESHOLDS[a.type][a.tier];
    const bThreshold = ACHIEVEMENT_TIERS_THRESHOLDS[b.type][b.tier];

    return aThreshold - bThreshold;
  });
};
