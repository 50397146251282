import { fr } from 'date-fns/locale';

import dayjs from '@eversity/services/dayjs';
import {
  getBrowserLocale,
  makeLocalStorageAdapter,
  setHtmlLangAttribute,
} from '@eversity/utils/web';

import {
  AVAILABLE_LOCALES,
  DEFAULT_LOCALE,
  LOCALE_STORAGE_KEY,
} from './constants';
import { type Bundle } from './types';

let localStorageAdapter: ReturnType<typeof makeLocalStorageAdapter>;

/**
 * Looks for the presence of the locale in the storage.
 *
 * @returns The locale if found, undefined otherwise.
 */
export const getStoredLocale = () => localStorageAdapter.get();

/**
 * Stores locale in the storage.
 *
 * @param locale - The locale to store.
 */
export const storeLocale = (locale: string) => {
  localStorageAdapter.set(locale);
};

/**
 * Gets the default locale for the app.
 *
 * @param availableLocales - The available locales for the app.
 * @param defaultLocale - The default locale for the app.
 * @returns - The locale to use for the app.
 */
export const getDefaultLocale = (
  availableLocales: string[],
  defaultLocale: string,
) => {
  const storedLocale = getStoredLocale();

  if (storedLocale) {
    return storedLocale;
  }

  const browserLocale = getBrowserLocale();

  if (availableLocales.includes(browserLocale)) {
    return browserLocale;
  }

  return defaultLocale;
};

/**
 * Imports the locale for the app.
 *
 * @param importLocalePath - The path to the locale.
 * @param locale - The locale to import.
 * @returns - The messages and date-fns locale for the app.
 */
export const importLocale = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  localePath: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  locale: string,
): Promise<Bundle> => {
  // It seems like the po file is not added to the webpack bundle
  // So switching from a language to another has no effect
  // It keeps using the default message
  // const { default: bundle } = await import(urlJoin(localePath, locale));
  // Temporary fix to set 'fr' as default dateFnsLocale
  return {
    messages: {},
    dateFnsLocale: fr,
  };
};

/**
 * Sets the locale for the app.
 *
 * @param locale - The locale to set.
 */
export const setAppLocale = (locale: string) => {
  dayjs.locale(locale);
  setHtmlLangAttribute(locale);
  storeLocale(locale);
};

/**
 * Initializes the locale for the app.
 * @param appName - The name of the app.
 */
export const initLocale = async (
  appName: string,
  localePath: string = './intl/locales',
  {
    availableLocales = AVAILABLE_LOCALES,
    defaultLocale = DEFAULT_LOCALE,
  }: {
    availableLocales?: string[];
    defaultLocale?: string;
  } = {},
) => {
  localStorageAdapter = makeLocalStorageAdapter(
    `${appName}:${LOCALE_STORAGE_KEY}`,
  );

  const locale = getDefaultLocale(availableLocales, defaultLocale);

  await importLocale(localePath, locale);

  setAppLocale(locale);
};
