import { type ReactElement } from 'react';
import {
  Fire,
  Headset,
  InboxDownloadR,
  ListFail,
  Log,
  Monitor,
  PlaybackProgress,
  ReplayMusic,
  Time,
  UserBusiness,
} from '@icon-park/react';

import { type StudentStatistics } from '@eversity/types/node';
import { Tile, type TILE_VARIANTS } from '@eversity/ui/design-system';

// Order matters for the statistics tiles
export enum STATISTICS_TYPES {
  TOTAL_SESSIONS_DURATION = 'TOTAL_SESSIONS_DURATION',
  MAX_CONNECTION_STREAK = 'MAX_CONNECTION_STREAK',
  NB_MEETINGS = 'NB_MEETINGS',
  ASSIGNMENTS = 'ASSIGNMENTS',
  COMPLETED_SEQUENCES = 'COMPLETED_SEQUENCES',
  DOWNLOADED_SEQUENCES = 'DOWNLOADED_SEQUENCES',
  NB_RESOURCES_SEEN = 'NB_RESOURCES_SEEN',
  NB_AUDIO_LISTENED = 'NB_AUDIO_LISTENED',
  NB_VIRTUAL_CLASSROOMS_SEEN = 'NB_VIRTUAL_CLASSROOMS_SEEN',
  NB_REPLAYS_SEEN = 'NB_REPLAYS_SEEN',
  EXERCISES = 'EXERCISES',
}

export enum STATISTICS_TILES_TYPES {
  GENERAL = 'GENERAL',
  PROGRESSION = 'PROGRESSION',
  VIRTUAL_CLASSROOMS = 'VIRTUAL_CLASSROOMS',
  ASSIGNMENTS = 'ASSIGNMENTS',
}

type StatisticType = keyof typeof STATISTICS_TYPES;
type StatisticTileType = keyof typeof STATISTICS_TILES_TYPES;

interface StatisticConfig {
  key: keyof StudentStatistics;
  tileType: StatisticTileType;
  icon: ReactElement;
}

export const STATISTICS_MAP: Record<StatisticType, StatisticConfig> = {
  [STATISTICS_TYPES.TOTAL_SESSIONS_DURATION]: {
    key: 'totalSessionsDuration',
    tileType: STATISTICS_TILES_TYPES.GENERAL,
    icon: <Time />,
  },

  [STATISTICS_TYPES.MAX_CONNECTION_STREAK]: {
    key: 'maxConnectionStreak',
    tileType: STATISTICS_TILES_TYPES.GENERAL,
    icon: <Fire />,
  },

  [STATISTICS_TYPES.NB_MEETINGS]: {
    key: 'nbMeetings',
    tileType: STATISTICS_TILES_TYPES.GENERAL,
    icon: <UserBusiness />,
  },

  [STATISTICS_TYPES.ASSIGNMENTS]: {
    key: 'assignments',
    tileType: STATISTICS_TILES_TYPES.ASSIGNMENTS,
    icon: <Time />,
  },

  [STATISTICS_TYPES.COMPLETED_SEQUENCES]: {
    key: 'completedSequences',
    tileType: STATISTICS_TILES_TYPES.PROGRESSION,
    icon: <Log />,
  },

  [STATISTICS_TYPES.DOWNLOADED_SEQUENCES]: {
    key: 'nbDownloadedSequences',
    tileType: STATISTICS_TILES_TYPES.PROGRESSION,
    icon: <InboxDownloadR />,
  },

  [STATISTICS_TYPES.EXERCISES]: {
    key: 'exercises',
    tileType: STATISTICS_TILES_TYPES.ASSIGNMENTS,
    icon: <ListFail />,
  },

  [STATISTICS_TYPES.NB_AUDIO_LISTENED]: {
    key: 'nbAudioListened',
    tileType: STATISTICS_TILES_TYPES.PROGRESSION,
    icon: <Headset />,
  },

  [STATISTICS_TYPES.NB_REPLAYS_SEEN]: {
    key: 'nbSeenReplays',
    tileType: STATISTICS_TILES_TYPES.VIRTUAL_CLASSROOMS,
    icon: <ReplayMusic />,
  },

  [STATISTICS_TYPES.NB_VIRTUAL_CLASSROOMS_SEEN]: {
    key: 'nbVirtualClassroomsSeen',
    tileType: STATISTICS_TILES_TYPES.VIRTUAL_CLASSROOMS,
    icon: <Monitor />,
  },

  [STATISTICS_TYPES.NB_RESOURCES_SEEN]: {
    key: 'nbSeenResources',
    tileType: STATISTICS_TILES_TYPES.PROGRESSION,
    icon: <PlaybackProgress />,
  },
};

export const STATISTICS_TYPES_OBJECT_MAP = Object.fromEntries(
  Object.entries(STATISTICS_MAP).map(([type, { key }]) => [type, key]),
);

export const STATISTICS_ICON_MAP = Object.fromEntries(
  Object.entries(STATISTICS_MAP).map(([type, { icon }]) => [type, icon]),
);

export const STATISTICS_TILES_TYPES_MAP = Object.fromEntries(
  Object.entries(STATISTICS_MAP).map(([type, { tileType }]) => [
    type,
    tileType,
  ]),
);

export const STATISTICS_TILES_TYPES_VARIANT_MAP: Record<
  STATISTICS_TILES_TYPES,
  TILE_VARIANTS
> = {
  [STATISTICS_TILES_TYPES.GENERAL]: Tile.VARIANTS.SECONDARY,
  [STATISTICS_TILES_TYPES.PROGRESSION]: Tile.VARIANTS.SUCCESS,
  [STATISTICS_TILES_TYPES.VIRTUAL_CLASSROOMS]: Tile.VARIANTS.PRIMARY,
  [STATISTICS_TILES_TYPES.ASSIGNMENTS]: Tile.VARIANTS.WARNING,
};
