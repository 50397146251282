import { css } from '@emotion/react';

export const dialogBody = css`
  display: flex;
  flex-direction: column;

  gap: 20px;
`;

export const achievementsList = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const achievement = css`
  display: flex;
  align-items: center;

  gap: 20px;
`;

export const achievementDescription = css`
  display: flex;
  flex-direction: column;
`;
