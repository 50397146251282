import { css, type Theme } from '@emotion/react';

export const container = (theme: Theme) => css`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${theme.breakpoints.small}) {
    min-width: 48px;
    min-height: 48px;
  }

  min-height: 37px;
  min-width: 37px;

  background-color: ${theme.colors.primary[25]};
  object-fit: cover;
  border-radius: 8px;
`;
