import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { ACHIEVEMENT_TIERS_THRESHOLDS } from '@eversity/domain/constants';
import { type StudentAchievement } from '@eversity/types/domain';
import { Achievement, Typography } from '@eversity/ui/design-system';
import { achievementsTitles } from '@eversity/ui/intl';

import messages from './AchievementsList.messages';
import * as styles from './AchievementsList.styles';

type AchievementsListProps = {
  achievements: StudentAchievement[];
};

const AchievementsListBase = ({ achievements }: AchievementsListProps) => {
  if (!achievements.length) {
    return (
      <Typography
        variant={Typography.VARIANTS.HEADING_4}
        css={styles.emptyState}
      >
        <FormattedMessage {...messages.NO_ACHIEVEMENTS} />
      </Typography>
    );
  }

  return achievements.map((achievement) => (
    <div
      css={styles.achievement}
      key={`${achievement.tier}-${achievement.type}`}
    >
      <Achievement
        isSeen
        type={achievement.type}
        tier={achievement.tier}
        key={`${achievement.tier}-${achievement.type}`}
      />

      <Typography variant={Typography.VARIANTS.BODY_SMALL_BOLD}>
        <FormattedMessage
          {...achievementsTitles[achievement.type]}
          values={{
            count:
              ACHIEVEMENT_TIERS_THRESHOLDS[achievement.type][achievement.tier],
          }}
        />
      </Typography>
    </div>
  ));
};

export const AchievementsList = memo(AchievementsListBase);
