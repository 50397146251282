import 'react-loading-skeleton/dist/skeleton.css';

import Skeleton from 'react-loading-skeleton';

import * as styles from './AchievementsList.styles';

export const AchievementsListSkeleton = () => {
  return Array.from({ length: 3 }).map((_, index) => (
    <div
      // eslint-disable-next-line react/no-array-index-key
      key={`skeleton-${index}`}
      css={styles.achievement}
    >
      <Skeleton
        height={40}
        width={40}
      />

      <Skeleton width={59} />
    </div>
  ));
};
