import { TinyColor } from '@ctrl/tinycolor';
import { css, type Theme } from '@emotion/react';

import { TILE_VARIANTS } from './constants';

export const container = (theme: Theme) => css`
  display: flex;
  align-items: center;
  gap: 20px;

  width: 100%;
  border-radius: 8px;
  padding: 20px 0px 20px 20px;

  &.${TILE_VARIANTS.PRIMARY} {
    background-color: ${theme.colors.primary[25]};
  }

  &.${TILE_VARIANTS.SECONDARY} {
    background-color: ${theme.colors.secondary[25]};
  }

  &.${TILE_VARIANTS.WARNING} {
    background-color: ${theme.colors.warning[25]};
  }

  &.${TILE_VARIANTS.SUCCESS} {
    background-color: ${theme.colors.success[25]};
  }

  &.isDisabled {
    background-color: ${theme.colors.gray[25]};
  }

  box-shadow:
    0px 0px 5px 1px
      ${new TinyColor(theme.colors.gray[900]).setAlpha(0.1).toRgbString()},
    0px 1px 2px 0px
      ${new TinyColor(theme.colors.gray[900]).setAlpha(0.1).toRgbString()};
`;

export const iconContainer = (theme: Theme) => css`
  padding: 15px;
  border-radius: 4px;

  &.${TILE_VARIANTS.PRIMARY} {
    background-color: ${theme.colors.primary[50]};
  }

  &.${TILE_VARIANTS.SECONDARY} {
    background-color: ${theme.colors.secondary[50]};
  }

  &.${TILE_VARIANTS.WARNING} {
    background-color: ${theme.colors.warning[50]};
  }

  &.${TILE_VARIANTS.SUCCESS} {
    background-color: ${theme.colors.success[50]};
  }

  &.isDisabled {
    background-color: ${theme.colors.gray[100]};
  }
`;

export const cardInfos = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
`;
