import { defineMessages } from 'react-intl';

import {
  TEACHING_UNIT_LESSON_USER_ROLES,
  TEACHING_UNIT_USER_ROLES,
  USER_ROLES,
} from '@eversity/domain/constants';

export const userFormMessages = defineMessages({
  PERSON_TITLE: { defaultMessage: 'Civilité ' },
  DATE_OF_BIRTH: { defaultMessage: 'Date de naissance' },
  LAST_NAME: { defaultMessage: 'Nom' },
  FIRST_NAME: { defaultMessage: 'Prénom' },
  ADDRESS: { defaultMessage: 'Adresse' },
  ADDRESS_SUPPLEMENT: { defaultMessage: "Complément d'adresse" },
  EMERGENCY_CONTACT: {
    defaultMessage: "Personne à contacter en cas d'urgence (téléphone)",
  },
  CITY: { defaultMessage: 'Ville' },
  POSTAL_CODE: { defaultMessage: 'Code postal' },
  COUNTRY: { defaultMessage: 'Pays' },
  PHONE: { defaultMessage: 'Téléphone' },
  EMAIL: { defaultMessage: 'Email' },
});

export const userRolesMessages = defineMessages<USER_ROLES>({
  [USER_ROLES.ADMIN]: { defaultMessage: 'Administrateur' },
  [USER_ROLES.ADMINISTRATIVE]: { defaultMessage: 'Administration' },
  [USER_ROLES.ANIMATION]: { defaultMessage: 'Animation Pédagogique' },
  [USER_ROLES.CAMPUS_TESTER]: { defaultMessage: 'Testeur Campus' },
  [USER_ROLES.COACH]: { defaultMessage: 'Coach' },
  [USER_ROLES.CONCEPTION]: { defaultMessage: 'Conception' },
  [USER_ROLES.CORRECTOR]: { defaultMessage: 'Correcteur' },
  [USER_ROLES.EDITOR]: { defaultMessage: 'Rédacteur' },
  [USER_ROLES.INTERNSHIPS]: { defaultMessage: 'Stage' },
  [USER_ROLES.SALES]: { defaultMessage: 'Commercial' },
  [USER_ROLES.SUPER_ADMIN]: { defaultMessage: 'Super Admin' },
  [USER_ROLES.SUPPORT]: { defaultMessage: 'Support' },
  [USER_ROLES.TEACHER]: { defaultMessage: 'Professeur' },
});

export const userRolesDescriptionsMessages = defineMessages({
  [USER_ROLES.ADMIN]: {
    defaultMessage:
      "Possède l'ensemble des droits sur la plateforme, sauf administrer n'importe quel rendu étudiant.",
  },
  [USER_ROLES.ADMINISTRATIVE]: {
    defaultMessage:
      'Visualise les étudiants et les recherches IA, administre les utilisateurs du BO et leur attribue les rôles.',
  },
  [USER_ROLES.ANIMATION]: {
    defaultMessage:
      "Visualise les informations des étudiants, visualise tous les rendus des étudiants et peut corriger n'importe lequel, peut administrer les classes virtuelles et les publications, accède au dashboard des Recherches IA.",
  },
  [USER_ROLES.CAMPUS_TESTER]: {
    defaultMessage:
      'Peut se connecter au campus avec son compte personnel via des classes de test.',
  },
  [USER_ROLES.COACH]: {
    defaultMessage: 'Visualise les informations des étudiants.',
  },
  [USER_ROLES.CONCEPTION]: {
    defaultMessage:
      'Administre les formations, les devoirs et les cours, attribue les cours aux rédacteurs.',
  },
  [USER_ROLES.CORRECTOR]: {
    defaultMessage:
      'Visualise les informations des étudiants, peut être positionné comme correcteur sur un devoir et peut corriger les rendus des étudiants sur ces devoirs.',
  },
  [USER_ROLES.EDITOR]: {
    defaultMessage: 'Rédige les cours sur lesquels il a été positionné.',
  },
  [USER_ROLES.INTERNSHIPS]: {
    defaultMessage: 'Visualise les informations des étudiants.',
  },
  [USER_ROLES.SALES]: {
    defaultMessage: "Aucun droit n'a été attribué à ce rôle pour le moment.",
  },
  [USER_ROLES.SUPER_ADMIN]: {
    defaultMessage:
      'Peut attribuer les rôles Administrateur et Support. Il faut néanmoins avoir le rôle Administrateur pour accéder aux écrans.',
  },
  [USER_ROLES.SUPPORT]: {
    defaultMessage:
      "Visualise les informations des étudiants et peut se connecter au campus en tant qu'un étudiant.",
  },
  [USER_ROLES.TEACHER]: {
    defaultMessage:
      'Accède aux cours en lecture seule, visualise les informations des étudiants et peut être positionné comme professeur sur un cours.',
  },
});

export const specificUserRolesMessages = defineMessages({
  REFERENT: { defaultMessage: 'Référent' },
});

export const teachingUnitUserRolesMessages =
  defineMessages<TEACHING_UNIT_USER_ROLES>({
    [TEACHING_UNIT_USER_ROLES.MANAGER]: { defaultMessage: 'Responsable' },
  });

export const teachingUnitLessonUserRolesMessages =
  defineMessages<TEACHING_UNIT_LESSON_USER_ROLES>({
    [TEACHING_UNIT_LESSON_USER_ROLES.TEACHER]: {
      defaultMessage: 'Professeur ',
    },
  });
