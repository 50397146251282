// EmptyState.styles.ts
import { css, type Theme } from '@emotion/react';

import { type EMPTY_STATE_SIZES, EMPTY_STATE_VARIANTS } from './constants';
import { EMPTY_STATE_SIZE_ICON_PADDING_MAPPING } from './EmptyState';

export const containerStyles = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  align-items: center;

  &.${EMPTY_STATE_VARIANTS.PRIMARY} {
    color: ${theme.colors.primary[700]};
  }

  &.${EMPTY_STATE_VARIANTS.TERTIARY} {
    color: ${theme.colors.tertiary[700]};
  }

  &.${EMPTY_STATE_VARIANTS.WARNING} {
    color: ${theme.colors.warning[700]};
  }
`;

export const iconWrapperStyles = (theme: Theme, size: EMPTY_STATE_SIZES) => css`
  padding: ${EMPTY_STATE_SIZE_ICON_PADDING_MAPPING[size]}px;
  border-radius: 100%;
  position: relative;

  &.${EMPTY_STATE_VARIANTS.PRIMARY} {
    background-color: ${theme.colors.primary[25]};
  }
  &.${EMPTY_STATE_VARIANTS.TERTIARY} {
    background-color: ${theme.colors.tertiary[25]};
  }
  &.${EMPTY_STATE_VARIANTS.WARNING} {
    background-color: ${theme.colors.warning[100]};
  }
`;

export const titleStyles = (theme: Theme) => css`
  &.${EMPTY_STATE_VARIANTS.PRIMARY} {
    color: ${theme.colors.primary[500]};
  }
  &.${EMPTY_STATE_VARIANTS.TERTIARY} {
    color: ${theme.colors.tertiary[500]};
  }
  &.${EMPTY_STATE_VARIANTS.WARNING} {
    color: ${theme.colors.warning[500]};
  }
`;

export const formatterStyles = css`
  margin-top: 36px;
  text-align: center;
`;
