import { getRemainingDaysBeforeLessonIsAvailable } from '@eversity/domain/lessons/utils';
import dayjs from '@eversity/services/dayjs';
import {
  type CourseClass,
  type CourseViewStudent,
  type TeachingUnitLessonUser,
  type UserLessonProgress,
} from '@eversity/types/domain';

/**
 * Get all lessons which the user has started/completed on a course.
 * Ignore those that he cannot access anymore.
 *
 * @param course - Student course.
 * @param userLessonProgresses - User lesson progresses.
 * @param accessStartDate - Registration date of the student on this course.
 * @returns Course lessons.
 */
export function getLessonsFromProgress(
  course: CourseViewStudent | undefined,
  userLessonProgresses: UserLessonProgress[] | undefined,
  accessStartDate: string,
) {
  return (
    (userLessonProgresses || [])
      // Only keep progresses of the current course.
      .filter((progress) => progress.course === course?.id)
      // Get data of teaching unit and lesson inside teaching unit.
      .map((progress) => {
        const teachingUnit = course.teachingUnits.find(
          ({ id }) => id === progress.teachingUnit,
        );
        const lesson = teachingUnit?.lessons.find(
          ({ lesson: { id } }) => id === progress.lesson,
        );

        return (
          !!teachingUnit &&
          !!lesson &&
          getRemainingDaysBeforeLessonIsAvailable(
            accessStartDate,
            lesson.availableAfterDays,
          ) <= 0 && {
            teachingUnit,
            teachingUnitLesson: lesson,
            userLessonProgress: progress,
          }
        );
      })
      // Ignore lessons which the user cannot access anymore (aka the optional flag changed).
      .filter(Boolean)
  );
}

/**
 * Return the list of started lessons of a course, sorted by last visited lesson DESC.
 *
 * @param course - Student course.
 * @param userLessonProgresses - User lesson progresses.
 * @param accessStartDate - Registration date of the student on this course.
 * @returns Course lessons.
 */
export function getLessonsToContinue(
  course: CourseViewStudent | undefined,
  userLessonProgresses: UserLessonProgress[] | undefined,
  accessStartDate: string,
) {
  return (
    getLessonsFromProgress(course, userLessonProgresses, accessStartDate)
      // Only keep lessons that are started but not completed.
      .filter(({ userLessonProgress: { progress } }) => progress < 100)
      // Sort by last progress update DESC.
      .sort((a, b) => {
        if (
          a.userLessonProgress?.updatedAt === b.userLessonProgress?.updatedAt
        ) {
          return 0;
        }
        return dayjs(a.userLessonProgress?.updatedAt).isBefore(
          dayjs(b.userLessonProgress?.updatedAt),
        )
          ? 1
          : -1;
      })
  );
}

/**
 * Find the metadata of a lesson in a course class.
 *
 * @param classLessons - Lessons metadata in class.
 * @param teachingUnitId - Teaching unit id of the lesson.
 * @param lessonId - Lesson id.
 * @returns Class lesson or undefined if not found.
 */
export function getClassLesson(
  classLessons: CourseClass['lessons'] | undefined,
  teachingUnitId: string,
  lessonId: string,
) {
  return classLessons?.find(
    ({ teachingUnit, lesson }) =>
      teachingUnit === teachingUnitId && lesson === lessonId,
  );
}

/**
 * Get all the teachers from a course
 * @param course Course data
 * @returns Array of teachers (type TeachingUnitLessonUser)
 */
export const getTeachersFromCourse = (
  course: CourseViewStudent,
): TeachingUnitLessonUser[] => {
  if (course) {
    return course.teachingUnits.flatMap((teachingUnit) =>
      teachingUnit.lessons.flatMap((lesson) => lesson.users),
    );
  }
  return [];
};

/**
 * Function to compute the current school year.
 * Before the 1st september, the interval is [(currentYear - 1), currentYear].
 * After, this is [currentYear, (currentYear + 1)].
 *
 * @returns The templated string with the interval (ex: 2023-2024)
 */
export const getCurrentSchoolYear = () => {
  const currentYear = dayjs().year();
  const currentMonth = dayjs().month();

  // We compare currentMonth with 8 (as september) since dayjs().month() starts to 0 as january.
  return currentMonth < 8
    ? `${currentYear - 1}-${currentYear}`
    : `${currentYear}-${currentYear + 1}`;
};
