import { Fragment, memo } from 'react';
import { Plus } from '@icon-park/react';
import cn from 'classnames';

import {
  type SIZES,
  SPARKLES_POSITION_MAPPING,
  type VARIANTS,
} from '../constants';
import * as styles from '../OrnamentWrapper.styles';

type SparkleProps = {
  size: SIZES;
  variant: VARIANTS;
};

export const Sparkles = memo(({ size, variant }: SparkleProps) => {
  const SPARKLES_POSITIONS = ['topRight', 'bottom', 'bottomLeft'];

  return (
    <Fragment>
      {SPARKLES_POSITIONS.map((position) => (
        <div
          css={styles.sparkles}
          className={cn(size, position)}
          key={`sparkle-${position}`}
        >
          <Plus
            strokeWidth={SPARKLES_POSITION_MAPPING[position][size].strokeWidth}
            size={SPARKLES_POSITION_MAPPING[position][size].size}
            fill={SPARKLES_POSITION_MAPPING[position].fill[variant]}
          />
        </div>
      ))}
    </Fragment>
  );
});
