import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { getExistingAchievements } from '@eversity/domain/users';
import { type StudentAchievement } from '@eversity/types/domain';
import { Achievement, Dialog, Typography } from '@eversity/ui/design-system';
import {
  achievementsDescriptions,
  achievementsTitles,
  globalMessages,
} from '@eversity/ui/intl';

import messages from './AllAchievementsDialog.messages';
import * as styles from './AllAchievementsDialog.styles';

type AllAchievementsDialogProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  achievements: StudentAchievement[];
};

const AllAchievementsDialogBase = ({
  isOpen,
  onRequestClose,
  achievements,
}: AllAchievementsDialogProps) => {
  const existingAchievements = getExistingAchievements();

  const isAchievementDisabled = (achievement) =>
    !achievements.some(
      (userAchievement) =>
        userAchievement.type === achievement.type &&
        userAchievement.tier === achievement.tier,
    );

  return (
    <Dialog
      size={Dialog.SIZES.SMALL}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <Dialog.Body css={styles.dialogBody}>
        <Typography variant={Typography.VARIANTS.HEADING_4}>
          <FormattedMessage {...messages.TITLE} />
        </Typography>

        <div css={styles.achievementsList}>
          {existingAchievements.map((achievement) => (
            <div
              css={styles.achievement}
              key={`achievement-${achievement.type}-${achievement.tier}`}
            >
              <Achievement
                tier={achievement.tier}
                type={achievement.type}
                key={`${achievement.type}-${achievement.tier}`}
                isDisabled={isAchievementDisabled(achievement)}
              />

              <div css={styles.achievementDescription}>
                <Typography variant={Typography.VARIANTS.BODY_MEDIUM_BOLD}>
                  <FormattedMessage
                    {...achievementsTitles[achievement.type]}
                    values={{ count: achievement.threshold }}
                  />
                </Typography>

                <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
                  <FormattedMessage
                    {...achievementsDescriptions[achievement.type]}
                    values={{ count: achievement.threshold }}
                  />
                </Typography>
              </div>
            </div>
          ))}
        </div>
      </Dialog.Body>

      <Dialog.Actions>
        <Dialog.Action
          variant={Dialog.Action.VARIANTS.PRIMARY}
          onClick={onRequestClose}
        >
          <FormattedMessage {...globalMessages.CLOSE} />
        </Dialog.Action>
      </Dialog.Actions>
    </Dialog>
  );
};

export const AllAchievementsDialog = memo(AllAchievementsDialogBase);
