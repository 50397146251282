import { type ReactNode, useEffect, useState } from 'react';

import {
  AVAILABLE_LOCALES,
  type Bundle,
  DEFAULT_LOCALE,
  getDefaultLocale,
  importLocale,
  setAppLocale,
} from '@eversity/ui/intl';

import { useMemoizedBundle } from '../../hooks/useMemoizedBundle';
import { LocaleContext } from '../locale-context/LocaleContext';

export type LocaleProviderProps = {
  children?: ReactNode;
  localePath?: string;
  availableLocales?: string[];
  defaultLocale?: string;
};

export const LocaleProvider = ({
  children = null,
  localePath = './intl/locales',
  availableLocales = AVAILABLE_LOCALES,
  defaultLocale = DEFAULT_LOCALE,
}: LocaleProviderProps) => {
  const [locale, setLocale] = useState(() =>
    getDefaultLocale(availableLocales, defaultLocale),
  );
  const [localeBundle, setLocaleBundle] = useState<Bundle>({
    messages: {},
    dateFnsLocale: null,
  });

  useEffect(() => {
    (async () => {
      const bundle = await importLocale(localePath, locale);
      setAppLocale(locale);
      setLocaleBundle(bundle);
    })();
  }, [localePath, locale]);

  const contextValue = useMemoizedBundle({
    locale,
    setLocale,
    messages: localeBundle.messages,
    dateFnsLocale: localeBundle.dateFnsLocale,
  });

  return (
    <LocaleContext.Provider value={contextValue}>
      {children}
    </LocaleContext.Provider>
  );
};

LocaleProvider.displayName = 'LocaleProvider';
