import { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import qs from 'query-string';

import { LoadingScreen } from '@eversity/ui/domain';
import { hide as hideSplashScreen } from '@eversity/ui/utils';

import { useAuth } from './context/auth-context';
import { FeedbackCampaignsManager } from './domain/feedbacks/campaigns/manager/FeedbackCampaignsManager';
import LegalDocumentsRequireAcceptance from './domain/legal-documents/require-acceptance/LegalDocumentsRequireAcceptance';
import { productTours } from './domain/product-tour/content/productTours';
import { ProductTourContextProvider } from './domain/product-tour/manager/ProductTourManager';
import useImpersonateEffect from './hooks/useImpersonateEffect';
import routes from './routes';

const AuthenticatedAppRouter = lazy(() => import('./AuthenticatedAppRouter'));
const LoginView = lazy(() => import('./views/login/LoginView'));
const NoCoursePage = lazy(() => import('./pages/no-course/NoCoursePage'));

const AppRouter = () => {
  // If the user is impersonated, set the token and reload the page
  useImpersonateEffect();

  const { user, isLoading } = useAuth();
  const location = useLocation();

  useEffect(() => {
    hideSplashScreen();
  }, []);

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        {/**
         * Render the login view when on /login.
         * For all other routes, render the AuthenticatedApp if the user is loaded.
         * If the user is not loaded but loading, render a LoadingScreen.
         * If the user is not loaded and not loading, redirect to /login.
         */}
        <Route
          path={routes.LOGIN.ROOT}
          element={<LoginView />}
        />

        {user ? (
          <Route
            path="*"
            element={
              // If the user has no courses, redirect to an empty state.
              // This also catches users who are not students.
              user.courses?.length ? (
                <FeedbackCampaignsManager>
                  <ProductTourContextProvider productTours={productTours}>
                    <LegalDocumentsRequireAcceptance />
                    <AuthenticatedAppRouter />
                  </ProductTourContextProvider>
                </FeedbackCampaignsManager>
              ) : (
                <NoCoursePage />
              )
            }
          />
        ) : (
          <Route
            path="*"
            element={
              isLoading ? (
                <LoadingScreen />
              ) : (
                <Navigate
                  to={{
                    pathname: routes.LOGIN.ROOT,
                    search: qs.stringify({
                      origin: `${location.pathname}${location.search || ''}`,
                    }),
                  }}
                />
              )
            }
          />
        )}
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
