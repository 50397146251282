import { TinyColor } from '@ctrl/tinycolor';
import { css, type Theme } from '@emotion/react';

export const trophyContainer = (theme: Theme) => css`
  position: relative;
  display: inline-block;
  border-radius: 50%;
  padding: 20px;
  background-color: ${theme.colors.warning[100]};
`;

export const cardBody = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: 40px;
  gap: 40px;
  border-radius: 8px;
  background-color: ${theme.colors.warning[15]};
  box-shadow:
    0px 1px 2px 0px
      ${new TinyColor(theme.colors.gray[900]).setAlpha(0.1).toRgbString()},
    0px 0px 5px 1px
      ${new TinyColor(theme.colors.gray[900]).setAlpha(0.1).toRgbString()};

  @media (max-width: ${theme.breakpoints.large}) {
    padding: 20px;
  }

  @media (min-width: ${theme.breakpoints.large}) {
    min-width: 300px;
  }
`;

export const achievementsList = css`
  display: flex;
  gap: 20px;
  justify-content: center;

  width: 100%;
`;

export const allAchievementsText = (theme: Theme) => css`
  color: ${theme.colors.primary[500]};
`;

export const achievementsZone = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 24px;
`;

export const ornamentWrapper = (theme: Theme) => css`
  @media (max-width: ${theme.breakpoints.large}) {
    display: none;
  }
`;
