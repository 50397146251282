export const commonRoutes = {
  GOOGLE_AUTH: {
    ROOT: '/auth/google',
  },
  LOGIN: {
    ROOT: '/login',
  },
  LOGOUT: {
    ROOT: '/auth/logout',
  },
  APP: {
    ROOT: '/',
  },
  ERROR: {
    ROOT: '/error',
  },
} as const;
