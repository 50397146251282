import {
  cloneElement,
  memo,
  type MemoExoticComponent,
  type ReactElement,
} from 'react';
import { useTheme } from '@emotion/react';

import { type COLOR_GROUPS } from '../../../config/colors/constants';
import { ICON_SHADOW_SIZES, ICON_SIZE_MAPPING } from './constants';
import * as styles from './IconShadow.styles';

export type IconShadowProps = {
  icon: ReactElement;
  colorGroup: COLOR_GROUPS;
  size?: ICON_SHADOW_SIZES;
  hasCircleBorder?: boolean;
};
export const IconShadowBase = ({
  icon,
  colorGroup,
  size = ICON_SHADOW_SIZES.MEDIUM,
  hasCircleBorder = false,
}: IconShadowProps) => {
  const theme = useTheme();

  return (
    /**
     * These divs display the icon. We need to add a box-shadow to the icon, but the viewbox
     * is larger than its content, so we display an empty div with the box-shadow, and render
     * the icon inside with absolute positioning to make it work visually.
     * Feel free to refactor if you have a better solution.
     */
    <div css={styles.iconContainer}>
      <div
        css={styles.iconPlaceholder}
        className={size}
      >
        {!hasCircleBorder ? (
          <div css={styles.customIconContainer(colorGroup)}>
            {cloneElement(icon, {
              size: ICON_SIZE_MAPPING[size] / 2,
              fill: [
                theme.colors[colorGroup][500],
                theme.colors[colorGroup][50],
              ],
            })}
          </div>
        ) : (
          <div css={styles.icon}>
            {cloneElement(icon, {
              size: ICON_SIZE_MAPPING[size],
              fill: [
                theme.colors[colorGroup][500],
                theme.colors[colorGroup][50],
              ],
            })}
          </div>
        )}
      </div>
    </div>
  );
};

IconShadowBase.displayName = 'IconShadow';

export const IconShadow: MemoExoticComponent<typeof IconShadowBase> & {
  SIZES?: typeof ICON_SHADOW_SIZES;
} = memo(IconShadowBase);

IconShadow.SIZES = ICON_SHADOW_SIZES;
