import {
  memo,
  type MemoExoticComponent,
  type PropsWithChildren,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import Floater from 'react-floater';
import cn from 'classnames';

import { useBoolState } from '@eversity/ui/utils';

import { Button, type ButtonProps } from '../../general/button/Button';
import { Card } from '../card/Card';

export enum TOOLTIP_BUTTON_TRIGGERS {
  CLICK = 'CLICK',
  HOVER = 'HOVER',
}

type TooltipButtonProps = {
  trigger?: TOOLTIP_BUTTON_TRIGGERS;
  title?: ReactNode;
  content?: ReactNode;
} & Omit<ButtonProps, 'content'> &
  PropsWithChildren;

const TooltipButtonBase = ({
  trigger = TOOLTIP_BUTTON_TRIGGERS.HOVER,
  content = null,
  title = null,
  children,
  ...props
}: TooltipButtonProps) => {
  const [isTooltipOpen, onOpenTooltip, onCloseTooltip] = useBoolState();
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const isClickable = useMemo(
    () => trigger === TOOLTIP_BUTTON_TRIGGERS.CLICK,
    [trigger],
  );

  const onMouseEnter = useCallback(() => {
    if (isClickable) {
      return;
    }

    onOpenTooltip();
  }, [isClickable, onOpenTooltip]);

  const onMouseLeave = useCallback(() => {
    if (isClickable) {
      return;
    }

    onCloseTooltip();
  }, [isClickable, onCloseTooltip]);

  const onClickChildren = useCallback(() => {
    if (!isClickable) {
      return;
    }

    if (isTooltipOpen) {
      onCloseTooltip();
    } else {
      onOpenTooltip();
    }
  }, [isClickable, isTooltipOpen, onCloseTooltip, onOpenTooltip]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node)
      ) {
        onCloseTooltip();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tooltipRef, onCloseTooltip]);

  return (
    <div ref={tooltipRef}>
      <Floater
        open={isTooltipOpen}
        content={
          <Card className={cn({ isTooltipOpen })}>
            {title && <Card.Title>{title}</Card.Title>}
            {content && <Card.Body>{content}</Card.Body>}
          </Card>
        }
        styles={{
          arrow: {
            length: 12,
            spread: 24,
          },
          wrapper: {
            cursor: isClickable ? 'pointer' : 'default',
          },
          container: {
            display: 'flex',
            minWidth: 0,
            padding: 0,
            borderRadius: 4,
          },
        }}
      >
        <Button
          {...props}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onClickChildren}
        >
          {children}
        </Button>
      </Floater>
    </div>
  );
};

export const TooltipButton: MemoExoticComponent<typeof TooltipButtonBase> & {
  TRIGGERS?: typeof TOOLTIP_BUTTON_TRIGGERS;
} = memo(TooltipButtonBase);

TooltipButton.TRIGGERS = TOOLTIP_BUTTON_TRIGGERS;
