import { memo } from 'react';

import { Tile, type TileProps } from '../tile/Tile';
import * as styles from './TileList.styles';

export type TileListProps = {
  /** List of props for each Tile */
  tiles: TileProps[];
  /** Propagation of loading state to Tiles */
  isLoading?: boolean;
};

/**
 * A container component that displays a grid of Tile components.
 *
 * @example
 * <TileList
 *   tiles={[
 *     { title: 'Tile 1', subtitle: 'Subtitle 1', icon: <Icon1 /> },
 *     { title: 'Tile 2', subtitle: 'Subtitle 2', icon: <Icon2 /> },
 *   ]}
 * />
 *
 * @returns {ReactElement} Rendered TileList component.
 */
export const TileList = memo(({ tiles, isLoading = false }: TileListProps) => (
  <div css={styles.container}>
    {tiles.map((tileProps, index) => (
      <Tile
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        {...tileProps}
        isLoading={tileProps.isLoading || isLoading}
      />
    ))}
  </div>
));

TileList.displayName = 'TileList';
