import { defineMessages } from 'react-intl';

import { DOCUMENT_ACTION_TYPES } from './constants';

export const actionMessages = defineMessages<DOCUMENT_ACTION_TYPES>({
  [DOCUMENT_ACTION_TYPES.VIEW]: {
    defaultMessage: 'Consulter le document',
  },

  [DOCUMENT_ACTION_TYPES.DOWNLOAD]: {
    defaultMessage: 'Télécharger le document',
  },
} as const);

export default defineMessages({
  GENERATED_AUTOMATICALLY: {
    defaultMessage: 'Document généré automatiquement',
  },
} as const);
