import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { LOCALSTORAGE_AUTH_TOKEN_KEY } from '../../domain/impersonate/constants';

export default () => {
  const [queryParams, setQueryParams] = useSearchParams();
  const impersonateToken = queryParams.get('impersonationToken');
  const [hasReloaded, setHasReloaded] = useState(false);

  useEffect(() => {
    if (impersonateToken && !hasReloaded) {
      // Set the token in local storage
      localStorage.setItem(LOCALSTORAGE_AUTH_TOKEN_KEY, impersonateToken);

      // Remove the impersonation token from the query params
      const newParams = new URLSearchParams(queryParams);
      newParams.delete('impersonationToken');
      setQueryParams(newParams, { replace: true });

      // Mark as reloaded and reload
      setHasReloaded(true);
      window.location.reload();
    }
  }, [impersonateToken, hasReloaded, queryParams, setQueryParams]);
};
