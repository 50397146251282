import { cloneElement, memo, type MemoExoticComponent, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { Plus } from '@icon-park/react';
import cn from 'classnames';

import {
  ACHIEVEMENT_TIERS,
  ACHIEVEMENT_TYPES,
} from '@eversity/domain/constants';

import * as styles from './Achievement.styles';
import AchievementIcon from './AchievementIcon';
import {
  ACHIEVEMENT_SIZE_MAPPING,
  ACHIEVEMENT_SIZES,
  ICON_COLOR_MAPPING,
  ICON_SIZE_MAPPING,
  SPARKLE_SIZE_MAPPING,
  TYPE_ICON_MAPPING,
} from './constants';

export type AchievementProps = {
  tier?: ACHIEVEMENT_TIERS;
  type?: ACHIEVEMENT_TYPES;
  size?: ACHIEVEMENT_SIZES;
  isDisabled?: boolean;
  isSeen?: boolean;
};

const AchievementBase = ({
  tier = ACHIEVEMENT_TIERS.NEUTRAL,
  type = ACHIEVEMENT_TYPES.CONNECTION_STREAK,
  size = ACHIEVEMENT_SIZES.MEDIUM,
  isDisabled = false,
  isSeen = true,
}: AchievementProps) => {
  const theme = useTheme();
  const iconsColor = ICON_COLOR_MAPPING(theme)[tier];

  const isDefault = useMemo(() => tier === ACHIEVEMENT_TIERS.NEUTRAL, [tier]);

  return (
    <div
      css={styles.mainContainer}
      className={cn(size, { isDisabled, isSeen })}
    >
      <AchievementIcon
        size={ACHIEVEMENT_SIZE_MAPPING[size]}
        circleFill={iconsColor[500]}
        circleStroke={iconsColor[700]}
        innerCircleFill={iconsColor[300]}
        pathStroke1={iconsColor[600]}
        pathStroke2={iconsColor[300]}
        pathStroke3={iconsColor[500]}
        pathStroke4={iconsColor[200]}
      />

      <div css={styles.iconContainer}>
        {cloneElement(TYPE_ICON_MAPPING[type], {
          size: ICON_SIZE_MAPPING[size],
          fill: [iconsColor[500], iconsColor[100]],
        })}
      </div>

      <div
        css={styles.firstSparkle}
        className={cn({ isDefault })}
      >
        <Plus
          strokeWidth={8}
          size={SPARKLE_SIZE_MAPPING[size]}
          fill={[theme.colors.gray[0]]}
        />
      </div>

      <div
        css={styles.secondSparkle}
        className={cn({ isDefault })}
      >
        <Plus
          size={SPARKLE_SIZE_MAPPING[size]}
          strokeWidth={8}
          fill={[theme.colors.gray[0]]}
        />
      </div>
    </div>
  );
};

export const Achievement: MemoExoticComponent<typeof AchievementBase> & {
  SIZES?: typeof ACHIEVEMENT_SIZES;
  TYPES?: typeof ACHIEVEMENT_TYPES;
  TIERS?: typeof ACHIEVEMENT_TIERS;
} = memo(AchievementBase);

Achievement.SIZES = ACHIEVEMENT_SIZES;
Achievement.TYPES = ACHIEVEMENT_TYPES;
Achievement.TIERS = ACHIEVEMENT_TIERS;
