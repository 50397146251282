import {
  cloneElement,
  memo,
  type MemoExoticComponent,
  type ReactElement,
  type ReactNode,
  useCallback,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useTheme } from '@emotion/react';
import { Download, Eyes, LoadingOne } from '@icon-park/react';
import cn from 'classnames';
import { noop } from 'lodash';

import {
  Button,
  ICON_SIZES,
  Typography,
  UnexpectedErrorToast,
} from '@eversity/ui/design-system';
import { FormattedRelativeTimeFromNow, useBoolState } from '@eversity/ui/utils';

import { DOCUMENT_ACTION_TYPES, DOCUMENT_LIST_SIZES } from './constants';
import messages, { actionMessages } from './DocumentListItem.messages';
import * as styles from './DocumentListItem.styles';

const ACTION_ICONS: Record<DOCUMENT_ACTION_TYPES, ReactElement> = {
  [DOCUMENT_ACTION_TYPES.VIEW]: <Eyes />,
  [DOCUMENT_ACTION_TYPES.DOWNLOAD]: <Download />,
};

export type DocumentListItemProps = {
  /** Document title */
  title: ReactNode;
  /** Icon from icon-park/react. */
  icon: ReactElement;
  /** Size variant. */
  size?: DOCUMENT_LIST_SIZES;
  /** Is the document currently active. */
  isActive?: boolean;
  /** Ignore the creation date. */
  isGeneratedOnTheFly?: boolean;
  /** Document creation date. Ignored if document is generated on the fly. */
  creationDate?: string;
  /** Type of action (view or download). */
  actionType: DOCUMENT_ACTION_TYPES;
  /** Button if another action is needed for the document */
  extraButton?: ReactNode;
  /** Disable the action button if needed */
  disabled?: boolean;
  /** Called when clicking on the action button. */
  onClick: () => void | Promise<void>;
  /** Useful for e2e testing */
  dataCy?: string;
};

export const DocumentListItemBase = ({
  title,
  icon,
  size = DOCUMENT_LIST_SIZES.FULL,
  isActive = false,
  isGeneratedOnTheFly = false,
  creationDate = null,
  actionType,
  extraButton = null,
  disabled = false,
  onClick = noop,
  dataCy,
}: DocumentListItemProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const [isLoading, onSetLoading, onStopLoading] = useBoolState();

  const onClickDownload = useCallback(async () => {
    try {
      onSetLoading();
      await onClick();
    } catch {
      toast.error(<UnexpectedErrorToast />);
    } finally {
      onStopLoading();
    }
  }, [onSetLoading, onClick, onStopLoading]);

  return (
    <div
      className={cn({ isActive })}
      css={styles.container}
    >
      <div css={styles.iconContainer}>
        {cloneElement(icon, {
          size: ICON_SIZES.LARGE,
          fill: [theme.colors.primary[500], theme.colors.primary[50]],
        })}
      </div>

      <div css={styles.main}>
        <Typography variant={Typography.VARIANTS.HEADING_5}>{title}</Typography>

        <div css={styles.right}>
          {size === DOCUMENT_LIST_SIZES.FULL && (
            <Typography
              variant={Typography.VARIANTS.BODY_SMALL_BOLD}
              css={styles.date}
            >
              {isGeneratedOnTheFly ? (
                <FormattedMessage {...messages.GENERATED_AUTOMATICALLY} />
              ) : (
                <FormattedRelativeTimeFromNow date={creationDate} />
              )}
            </Typography>
          )}

          {extraButton}

          <Button
            icon={!isLoading ? ACTION_ICONS[actionType] : <LoadingOne spin />}
            disabled={isLoading || disabled}
            size={Button.SIZES.LARGE}
            variant={Button.VARIANTS.PRIMARY}
            onClick={
              actionType === DOCUMENT_ACTION_TYPES.DOWNLOAD
                ? onClickDownload
                : onClick
            }
            aria-label={intl.formatMessage(actionMessages[actionType])}
            data-cy={dataCy}
          >
            {size === DOCUMENT_LIST_SIZES.FULL && (
              <FormattedMessage {...actionMessages[actionType]} />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

DocumentListItemBase.displayName = 'DocumentListItem';

export const DocumentListItem = memo(
  DocumentListItemBase,
) as MemoExoticComponent<typeof DocumentListItemBase> & {
  ACTION_TYPES?: typeof DOCUMENT_ACTION_TYPES;
};

DocumentListItem.ACTION_TYPES = DOCUMENT_ACTION_TYPES;
