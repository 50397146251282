import {
  cloneElement,
  memo,
  type MemoExoticComponent,
  type ReactElement,
  type ReactNode,
} from 'react';
import { useTheme } from '@emotion/react';
import { isFunction } from 'lodash';

import { useMemoizedBundle } from '@eversity/ui/utils';

import { type TYPOGRAPHY_VARIANTS } from '../../../config/typography/constants';
import { Typography } from '../../general/typography/Typography';
import { HtmlFormatter } from '../html-formatter/HtmlFormatter';
import { EMPTY_STATE_SIZES, EMPTY_STATE_VARIANTS } from './constants';
import * as styles from './EmptyState.styles';
import { EmptyStateContext } from './EmptyStateContext';
import {
  EMPTY_STATE_SIZE_TYPOGRAPHY_MAPPING,
  Paragraph,
  type TypographyMapping,
} from './paragraph/Paragraph';

const EMPTY_STATE_SIZE_TITLE_TYPOGRAPHY_MAPPING: Record<
  EMPTY_STATE_SIZES,
  TYPOGRAPHY_VARIANTS
> = {
  [EMPTY_STATE_SIZES.SMALL]: Typography.VARIANTS.HEADING_4,
  [EMPTY_STATE_SIZES.MEDIUM]: Typography.VARIANTS.HEADING_3,
  [EMPTY_STATE_SIZES.LARGE]: Typography.VARIANTS.HEADING_2,
};

const EMPTY_STATE_SIZE_ICON_SIZE_MAPPING: Record<EMPTY_STATE_SIZES, number> = {
  [EMPTY_STATE_SIZES.SMALL]: 50,
  [EMPTY_STATE_SIZES.MEDIUM]: 80,
  [EMPTY_STATE_SIZES.LARGE]: 120,
};

export const EMPTY_STATE_SIZE_ICON_PADDING_MAPPING: Record<
  EMPTY_STATE_SIZES,
  number
> = {
  [EMPTY_STATE_SIZES.SMALL]: 20,
  [EMPTY_STATE_SIZES.MEDIUM]: 30,
  [EMPTY_STATE_SIZES.LARGE]: 50,
};

const EMPTY_STATE_SIZE_ICON_STROKE_WIDTH_MAPPING: Record<
  EMPTY_STATE_SIZES,
  number
> = {
  [EMPTY_STATE_SIZES.SMALL]: 2,
  [EMPTY_STATE_SIZES.MEDIUM]: 1.5,
  [EMPTY_STATE_SIZES.LARGE]: 1,
};

export type EmptyStateProps = {
  title: ReactNode;
  icon?: ReactElement;
  iconWrapper?: ReactElement;
  size?: EMPTY_STATE_SIZES;
  children?:
    | ReactNode
    | ((props: { typography: TypographyMapping }) => ReactNode);
  variant?: EMPTY_STATE_VARIANTS;
};

export const EmptyStateBase = ({
  title,
  icon = null,
  iconWrapper = null,
  size = EMPTY_STATE_SIZES.MEDIUM,
  variant = EMPTY_STATE_VARIANTS.PRIMARY,
  children = null,
}: EmptyStateProps) => {
  const theme = useTheme();

  const contextValue = useMemoizedBundle({
    size,
  });

  return (
    <EmptyStateContext.Provider value={contextValue}>
      <div
        className={variant}
        css={styles.containerStyles}
      >
        {icon &&
          cloneElement(
            iconWrapper || <div />,
            {},
            <div
              className={variant}
              css={styles.iconWrapperStyles(theme, size)}
            >
              {cloneElement(icon, {
                size: EMPTY_STATE_SIZE_ICON_SIZE_MAPPING[size],
                ...(variant === EMPTY_STATE_VARIANTS.PRIMARY && {
                  fill: [theme.colors.primary[400], theme.colors.primary[50]],
                }),
                ...(variant === EMPTY_STATE_VARIANTS.TERTIARY && {
                  fill: [theme.colors.tertiary[300], theme.colors.tertiary[50]],
                }),
                ...(variant === EMPTY_STATE_VARIANTS.WARNING && {
                  fill: [theme.colors.warning[500], theme.colors.warning[50]],
                }),
                strokeWidth: EMPTY_STATE_SIZE_ICON_STROKE_WIDTH_MAPPING[size],
              })}
            </div>,
          )}
        <HtmlFormatter css={styles.formatterStyles}>
          <Typography
            className={variant}
            variant={EMPTY_STATE_SIZE_TITLE_TYPOGRAPHY_MAPPING[size]}
            css={styles.titleStyles}
          >
            {title}
          </Typography>

          {isFunction(children)
            ? children({
                typography: EMPTY_STATE_SIZE_TYPOGRAPHY_MAPPING[size],
              })
            : children}
        </HtmlFormatter>
      </div>
    </EmptyStateContext.Provider>
  );
};

EmptyStateBase.displayName = 'EmptyState';

export const EmptyState: MemoExoticComponent<typeof EmptyStateBase> & {
  SIZES?: typeof EMPTY_STATE_SIZES;
  Paragraph?: typeof Paragraph;
  VARIANTS?: typeof EMPTY_STATE_VARIANTS;
} = memo(EmptyStateBase);

EmptyState.SIZES = EMPTY_STATE_SIZES;
EmptyState.Paragraph = Paragraph;
EmptyState.VARIANTS = EMPTY_STATE_VARIANTS;

/**
 * Note to self: we will use new svgs in the future. In order not to break existing empty states,
 * just add another prop (picture) and render that instead of the icon.
 */
