import {
  addClassname,
  removeClassname,
  removeClassnames,
} from '@eversity/utils/web';

/**
 * Hide splash screen.
 *
 * @returns Resolved when fading away is finished.
 */
export const hide = () =>
  new Promise<void>((resolve) => {
    const splash = document.getElementById('splash');
    const body = document.getElementsByTagName('body')[0];

    if (!splash) {
      resolve();
      return;
    }

    addClassname(splash, 'splash--fading');

    // Give it time to fade away before hiding it.
    setTimeout(() => {
      addClassname(splash, 'splash--hidden');
      removeClassname(body, 'body--has-splash');
      resolve();
    }, 500);
  });

/**
 * Show splash screen.
 */
export const show = () => {
  const splash = document.getElementById('splash');
  const body = document.getElementsByTagName('body')[0];

  if (!splash) {
    return;
  }

  addClassname(body, 'body--has-splash');
  removeClassnames(splash, 'splash--fading splash--hidden');
};
