import { COLORS } from '../../../themes/default/colors';
import { type SparklesPositionMappingConfig } from './types';

export enum SIZES {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum VARIANTS {
  PRIMARY = 'primary',
  TERTIARY = 'tertiary',
  WARNING = 'warning',
}

export const SPARKLES_POSITION_MAPPING: SparklesPositionMappingConfig = {
  topRight: {
    [SIZES.SMALL]: {
      top: 10,
      right: -20,
      strokeWidth: 6,
      size: 20,
    },
    [SIZES.MEDIUM]: {
      top: 20,
      right: -30,
      strokeWidth: 8,
      size: 30,
    },
    [SIZES.LARGE]: {
      top: 30,
      right: -40,
      strokeWidth: 10,
      size: 40,
    },
    fill: {
      [VARIANTS.PRIMARY]: COLORS.primary[400],
      [VARIANTS.TERTIARY]: COLORS.tertiary[400],
      [VARIANTS.WARNING]: COLORS.warning[400],
    },
  },
  bottom: {
    [SIZES.SMALL]: {
      bottom: -10,
      left: 10,
      strokeWidth: 6,
      size: 14,
    },
    [SIZES.MEDIUM]: {
      bottom: -20,
      left: 20,
      strokeWidth: 8,
      size: 20,
    },
    [SIZES.LARGE]: {
      bottom: -30,
      left: 30,
      strokeWidth: 10,
      size: 26,
    },
    fill: {
      [VARIANTS.PRIMARY]: COLORS.primary[400],
      [VARIANTS.TERTIARY]: COLORS.tertiary[400],
      [VARIANTS.WARNING]: COLORS.warning[400],
    },
  },
  bottomLeft: {
    [SIZES.SMALL]: {
      bottom: 0,
      left: -15,
      strokeWidth: 6,
      size: 18,
    },
    [SIZES.MEDIUM]: {
      bottom: 0,
      left: -30,
      strokeWidth: 8,
      size: 26,
    },
    [SIZES.LARGE]: {
      bottom: 0,
      left: -42,
      strokeWidth: 10,
      size: 32,
    },
    fill: {
      [VARIANTS.PRIMARY]: COLORS.primary[100],
      [VARIANTS.TERTIARY]: COLORS.tertiary[100],
      [VARIANTS.WARNING]: COLORS.warning[100],
    },
  },
};
