export enum EMPTY_STATE_SIZES {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum EMPTY_STATE_VARIANTS {
  PRIMARY = 'primary',
  TERTIARY = 'tertiary',
  WARNING = 'warning',
}
