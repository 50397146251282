import {
  memo,
  type MemoExoticComponent,
  type PropsWithChildren,
  type ReactNode,
} from 'react';

import { SIZES, VARIANTS } from './constants';
import { Embrace } from './ornaments/Embrace';
import { MagnifyingGlass } from './ornaments/MagnifyingGlass';
import { Sparkles } from './ornaments/Sparkles';
import * as styles from './OrnamentWrapper.styles';
import {
  type OrnamentWrapperElement,
  type OrnamentWrapperProps,
} from './types';

const Ornaments: Record<
  OrnamentWrapperElement,
  (props: OrnamentWrapperProps) => ReactNode
> = {
  embrace: () => <Embrace />,
  magnifyingGlass: () => <MagnifyingGlass />,
  sparkles: ({ size = SIZES.MEDIUM, variant = VARIANTS.WARNING }) => (
    <Sparkles
      size={size}
      variant={variant}
    />
  ),
};

/**
 * A wrapper component that decorates its children with different visual ornaments
 * based on the specified `element`, `size`, and `variant` props.
 *
 * ### Supported Elements
 * - `embrace`: Displays SVG decorations such as head, left hand, and right hand.
 * - `magnifyingGlass`: Displays a single magnifying glass SVG.
 * - `sparkles`: Displays sparkles positioned around the wrapper based on size and variant.
 *
 * @example
 * // Example usage of the OrnamentWrapper component
 * <OrnamentWrapper element="sparkles" size="large" variant="primary">
 *   <h1>Hello, World!</h1>
 * </OrnamentWrapper>
 *
 * @see {@link SIZES} for the size options.
 * @see {@link VARIANTS} for the variant options.
 */

const OrnamentWrapperBase = ({
  children,
  element = 'embrace',
  size = SIZES.MEDIUM,
  variant = VARIANTS.WARNING,
}: PropsWithChildren<OrnamentWrapperProps>) => {
  const OrnamentComponent = Ornaments[element];

  return (
    <div css={styles.container}>
      {OrnamentComponent && OrnamentComponent({ size, variant })}

      {children}
    </div>
  );
};

export const OrnamentWrapper: MemoExoticComponent<
  typeof OrnamentWrapperBase
> & {
  SIZES?: typeof SIZES;
  VARIANTS?: typeof VARIANTS;
} = memo(OrnamentWrapperBase);

OrnamentWrapper.SIZES = SIZES;
OrnamentWrapper.VARIANTS = VARIANTS;
