import { createContext, useContext } from 'react';
import { fr } from 'date-fns/locale';
import { noop } from 'lodash';

import { type Bundle } from '@eversity/ui/intl';

type LocaleContextValue = {
  locale: string;
  setLocale: (newLocale: string) => void;
  messages: Bundle['messages'];
  dateFnsLocale: Bundle['dateFnsLocale'];
};

export const LocaleContext = createContext<LocaleContextValue>({
  locale: 'fr',
  setLocale: noop,
  messages: {},
  dateFnsLocale: fr,
});

export const useLocaleContext = () => useContext(LocaleContext);
