import { USER_LESSON_PROGRESS_SEQUENCE_STATUSES as SEQUENCE_STATUSES } from '@eversity/domain/constants';
import { type UserLessonProgress } from '@eversity/types/domain';

/**
 * Get the id of the first sequence not completed.
 *
 * @param lessonVersion - Current lesson version.
 * @param userProgress - User progress.
 * @returns Sequence id, or undefined if none.
 */
export function getFirstIncompleteSequenceId(
  lessonVersion?: { sequences: (string | { sequenceVersionId: string })[] },
  userProgress?: UserLessonProgress,
): string | undefined {
  return lessonVersion?.sequences
    .map(
      (sequence) =>
        userProgress?.sequences[
          typeof sequence === 'string' ? sequence : sequence.sequenceVersionId
        ],
    )
    .find(({ status }) => status !== SEQUENCE_STATUSES.COMPLETED)
    ?.sequenceVersionId;
}

/**
 * Get the total time spent in seconds of a user on a lesson.
 *
 * @param userLessonProgress - User lesson progress.
 * @returns Total time spent in seconds.
 */
export function getLessonTotalTimeSpent(
  userLessonProgress?: UserLessonProgress,
): number {
  return Object.values(userLessonProgress?.sequences || {})?.reduce(
    (acc, sequence) => acc + (sequence.timeSpent || 0),
    0,
  );
}

/**
 * Find a user lesson progress from his progresses list.
 *
 * @param userLessonProgresses - List of all progresses of the user.
 * @param courseId - Course id.
 * @param classId - Class id.
 * @param teachingUnitId - Teaching unit id id.
 * @param lessonId - Lesson id.
 * @returns User lesson progress.
 */
export function findUserLessonProgress(
  userLessonProgresses: UserLessonProgress[] | undefined,
  courseId: string,
  classId: string,
  teachingUnitId: string,
  lessonId: string,
): UserLessonProgress | undefined {
  return userLessonProgresses?.find(
    (userLessonProgress) =>
      userLessonProgress.course === courseId &&
      userLessonProgress.class === classId &&
      userLessonProgress.teachingUnit === teachingUnitId &&
      userLessonProgress.lesson === lessonId,
  );
}
