export type EnvironmentVariables = {
  [key: string]: string;
};

declare global {
  interface Window {
    env: EnvironmentVariables;
  }
}

/**
 * Returns runtime environment variable if set.
 * Runtime environment is configured in `src/index.html.ejs` inside window.env.
 * They are configured using placeholders as in window.env = { A : '$MY_APP_A' }.
 *
 * @param variableName - The variable name.
 * @returns  The variable value.
 */
export const getEnvironmentVariable = <T = string>(
  variableName: string,
  {
    transform = (v) => v as T,
    defaultValue,
  }: {
    transform?: (v?: string) => T;
    defaultValue?: T;
  } = {},
) => {
  let varValue: string | undefined = window.env?.[variableName];

  if (varValue === `$${variableName}` || varValue === '') {
    varValue = undefined;
  }

  if (varValue === undefined && defaultValue !== undefined) {
    return defaultValue;
  }

  return transform(varValue);
};
