import { TinyColor } from '@ctrl/tinycolor';
import { css, type Theme } from '@emotion/react';

export const container = (theme: Theme) => css`
  display: flex;
  align-items: stretch;
  border: 1px solid ${theme.colors.gray[100]};
  border-radius: 4px;
  background-color: ${theme.colors.gray[0]};

  &.isActive {
    border-color: ${theme.colors.primary[500]};
    box-shadow: 0 0 0 4px
      ${new TinyColor(theme.colors.primary[500]).setAlpha(0.15).toRgbString()};
  }
`;

export const iconContainer = (theme: Theme) => css`
  flex: none;
  display: flex;
  align-items: center;
  padding: 10px;
  border-right: 1px solid ${theme.colors.gray[100]};
`;

export const main = css`
  flex: 1;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

export const right = (theme: Theme) => css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px 20px;
  flex-shrink: 1;

  @media (max-width: ${theme.breakpoints.medium}) {
    gap: 10px;
  }
`;

export const date = (theme: Theme) => css`
  color: ${theme.colors.gray[400]};
  text-align: right;
`;
