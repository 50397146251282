import { css, type Theme } from '@emotion/react';

export const container = css`
  display: flex;
  flex-direction: column;

  height: 100%;
  gap: 40px;
  flex: 1;
`;

export const item = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  gap: 20px;

  &.medal-item {
    margin-top: 20px;
  }

  @media (min-width: ${theme.breakpoints.large}) {
    &.medal-item {
      display: none;
    }
  }
`;
