import { TinyColor } from '@ctrl/tinycolor';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { SWITCH_INPUT_SIDES, SWITCH_SIZES } from './constants';

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Control = styled.div`
  transition: ${(props) => props.theme.transitions.default()};

  background-color: ${(props) => props.theme.colors.gray[0]};

  border-style: solid;
  position: relative;

  display: flex;
  justify-content: flex-end;

  &.checked {
    justify-content: start;
  }
`;

export const InnerControl = styled.div`
  position: absolute;
  overflow: hidden;
`;

export const ControlContainer = styled.div`
  flex: none;

  transition: ${(props) => props.theme.transitions.default()};

  position: relative;
  overflow: hidden;
`;

export const SwitchContainer = styled.label`
  display: flex;
  align-items: flex-start;
  gap: 8px;

  ${Control} {
    transform: translate3d(-75%, 0, 0);
  }

  &.checked {
    ${Control} {
      transform: translate3d(25%, 0, 0);
    }
  }

  &:not(.disabled) {
    cursor: pointer;
    color: ${(props) => props.theme.colors.gray[700]};

    ${ControlContainer} {
      background-color: ${(props) => props.theme.colors.gray[300]};
    }

    ${Control} {
      border-color: ${(props) => props.theme.colors.gray[300]};
    }

    &.focused {
      ${ControlContainer} {
        box-shadow: 0 0 0 4px
          ${(props) =>
            new TinyColor(props.theme.colors.primary[500])
              .setAlpha(0.15)
              .toRgbString()};
      }
    }

    &.checked {
      ${ControlContainer} {
        background-color: ${(props) => props.theme.colors.primary[500]};
      }

      ${Control} {
        border-color: ${(props) => props.theme.colors.primary[500]};
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    color: ${(props) => props.theme.colors.gray[300]};

    ${ControlContainer} {
      background-color: ${(props) => props.theme.colors.gray[100]};
    }

    ${Control} {
      border-color: ${(props) => props.theme.colors.gray[100]};
    }

    &.checked {
      ${ControlContainer} {
        background-color: ${(props) => props.theme.colors.primary[100]};
      }

      ${Control} {
        border-color: ${(props) => props.theme.colors.primary[100]};
      }
    }
  }
  &.${SWITCH_INPUT_SIDES.RIGHT} {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  &.${SWITCH_SIZES.SMALL} {
    &.checked {
      ${Control} {
        transform: translate3d(21%, 0, 0);
      }
    }

    ${ControlContainer} {
      width: 28px;
      height: 16px;
      border-radius: 16px;

      top: 2px;
    }

    ${InnerControl} {
      height: 12px;
      width: 24px;
      border-radius: 22px;
      top: 2px;
      left: 2px;
    }

    ${Control} {
      top: -2px;
      left: -2px;
      width: 56px;
      height: 16px;
      border-radius: 16px;
      border-width: 2px;

      transform: translate3d(-71%, 0, 0);
    }
  }

  &.${SWITCH_SIZES.MEDIUM} {
    &.checked {
      ${Control} {
        transform: translate3d(25%, 0, 0);
      }
    }

    ${ControlContainer} {
      width: 44px;
      height: 22px;
      border-radius: 22px;
    }

    ${InnerControl} {
      height: 18px;
      width: 40px;
      border-radius: 22px;
      top: 2px;
      left: 2px;
    }

    ${Control} {
      top: -2px;
      left: -2px;
      width: 88px;
      height: 22px;
      border-radius: 22px;
      border-width: 2px;

      transform: translate3d(-75%, 0, 0);
    }
  }
`;

export const input = css`
  /* We need to keep it in the DOM to make it focusable. */
  position: fixed;
  top: -1000px;
  left: -1000px;
`;

export const description = css`
  margin-left: 8px;
`;
