/* eslint-disable react/jsx-filename-extension */
import ReactDOM from 'react-dom/client';

import { bootstrapSentryReact } from '@eversity/services/sentry/react';
import { initLocale } from '@eversity/ui/intl';

import config from './config/env';
import App from './react/context';

(async function main() {
  await Promise.all([
    initLocale(config.app.name),
    bootstrapSentryReact(config.sentry),
  ]);

  ReactDOM.createRoot(document.getElementById('root')).render(<App />);
})();
