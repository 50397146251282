import { css, type Theme } from '@emotion/react';

import { ICON_SHADOW_SIZES, ICON_SIZE_MAPPING } from './constants';

export const iconContainer = css`
  position: relative;
`;

export const iconPlaceholder = css`
  &.${ICON_SHADOW_SIZES.EXTRA_SMALL} {
    width: ${ICON_SIZE_MAPPING[ICON_SHADOW_SIZES.EXTRA_SMALL]}px;
    height: ${ICON_SIZE_MAPPING[ICON_SHADOW_SIZES.EXTRA_SMALL]}px;
  }

  &.${ICON_SHADOW_SIZES.SMALL} {
    width: ${ICON_SIZE_MAPPING[ICON_SHADOW_SIZES.SMALL]}px;
    height: ${ICON_SIZE_MAPPING[ICON_SHADOW_SIZES.SMALL]}px;
  }

  &.${ICON_SHADOW_SIZES.MEDIUM} {
    width: ${ICON_SIZE_MAPPING[ICON_SHADOW_SIZES.MEDIUM]}px;
    height: ${ICON_SIZE_MAPPING[ICON_SHADOW_SIZES.MEDIUM]}px;
  }

  &.${ICON_SHADOW_SIZES.LARGE} {
    width: ${ICON_SIZE_MAPPING[ICON_SHADOW_SIZES.LARGE]}px;
    height: ${ICON_SIZE_MAPPING[ICON_SHADOW_SIZES.LARGE]}px;
  }
`;

export const customIconContainer =
  (colorGroup: string) => (theme: Theme) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 100%;
    border-radius: 100%;

    border: 2px solid ${theme.colors[colorGroup][500]};
    background-color: ${theme.colors[colorGroup][50]};
  `;

export const icon = css`
  position: absolute;
  top: -2px;
  left: -2px;
`;
