import { memo } from 'react';
import { css } from '@emotion/react';

import { ImpulseSpinner, Logo } from '@eversity/ui/design-system';

// Feel free to edit the loading screen.
const LoadingScreenBase = () => (
  <div
    css={css`
      width: 100vw;
      height: 100vh;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
    `}
  >
    <Logo
      css={css`
        width: 68px;
        height: 96px;
      `}
    />

    <ImpulseSpinner />
  </div>
);

LoadingScreenBase.displayName = 'LoadingScreen';

export const LoadingScreen = memo(LoadingScreenBase);
