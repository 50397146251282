import { css, type Theme } from '@emotion/react';

export const emptyState = (theme: Theme) => css`
  color: ${theme.colors.warning[500]};
  text-align: center;
`;

export const achievement = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: space-between;
  gap: 10px;
`;
